import { createContext, useCallback, useEffect, useState } from 'react';
import { getOrgInfo as serviceGetOrgInfo } from '../config/services';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { privateRoutes } from '../config/constant';

const getFaviconEl = () => {
    return document.getElementById("favicon");
};
// Create Context
const OrgInfoContext = createContext(null);
export default OrgInfoContext;

export const OrgInfoContextProvider = (props) => {
    // // Loader Till Org Info Rcvd
    let history = useHistory();
    const [isMainLoading, setIsMainLoading] = useState(true);
    const [fullBgBlur, setFullBgBlur] = useState(false);
    const [orgInfo, setOrgInfo] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [productQtyModelDesId, setProductQtyModelDesId] = useState(null);
    const [productCardActiveInfo, setProductCardActiveInfo] = useState(null);
    const { storeName } = useParams();
    const [currentLoginFlowStatus, setCurrentLoginFlowStatus] = useState(0);
    const location = useLocation();
    const isPrivatePath = privateRoutes.includes(location.pathname.toString().split('/').pop());

    const getOrgInfo = useCallback(async () => {
        try {
            const apiOrgInfo = await serviceGetOrgInfo(storeName);
            if (apiOrgInfo && apiOrgInfo.status) {
                setOrgInfo(apiOrgInfo.data);
                document.title = apiOrgInfo.data.organization_name;
                const favicon = getFaviconEl();
                favicon.href = apiOrgInfo.data.organization_logo;
            } else {
                throw apiOrgInfo;
            }

            const loggedInData = window.sessionStorage.getItem(storeName);

            if (loggedInData) {
                try {
                    const loginData = JSON.parse(loggedInData);
                    setCustomerInfo(loginData);
                } catch (error) {
                    window.sessionStorage.removeItem(storeName);
                    if (isPrivatePath) {
                        setCurrentLoginFlowStatus(1);
                    }
                }
            } else {
                if (isPrivatePath || (apiOrgInfo.data && apiOrgInfo.data.is_public == 0)) {
                    setCurrentLoginFlowStatus(1);
                }
            }
        } catch (err) {
            console.error('[OrgInfo.context Excecption]', err);
            history.push("/404");
        } finally {
            setIsMainLoading(false);
        }
    }, []);

    useEffect(() => {
        getOrgInfo();
    }, [getOrgInfo]);

    useEffect(() => {
        if ((currentLoginFlowStatus > 0 && currentLoginFlowStatus < 3) || productQtyModelDesId) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [currentLoginFlowStatus, productQtyModelDesId]);

    useEffect(() => {
        // Save context data to sessionStorage when it changes
        localStorage.setItem('orgInfoContext', JSON.stringify({
            productCardActiveInfo,
            productQtyModelDesId,
            fullBgBlur,
            orgInfo,
            customerInfo,
            currentLoginFlowStatus
        }));
    }, [productCardActiveInfo, productQtyModelDesId, fullBgBlur, orgInfo, customerInfo, currentLoginFlowStatus]);


    return (
        <OrgInfoContext.Provider value={{ productCardActiveInfo, setProductCardActiveInfo, productQtyModelDesId, setProductQtyModelDesId, fullBgBlur, setFullBgBlur, orgInfo, isMainLoading, setIsMainLoading, customerInfo, setCustomerInfo, currentLoginFlowStatus, setCurrentLoginFlowStatus }}>
            {props.children}
        </OrgInfoContext.Provider>
    );
};
