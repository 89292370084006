import React, { useState, useEffect, useMemo } from "react";
import { PropTypes } from "prop-types";
import { Dropdown, Modal, Button, Tab, Row, Col, Nav, ListGroup, DropdownButton } from "react-bootstrap";
import { getOrgTags, getOrgColors, getOrgSizes, getOrgDesignMinMaxPrice } from "../config/services";
import SearchBarProductFilter from '../Components/SearchBarProductFilter';
import InfiniteScroll from 'react-infinite-scroller';
import MultiRangeSlider from "multi-range-slider-react";
import { cloneDeep } from 'lodash'

const ProductFilterModal = (props) => {
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ orgTagInfo, setOrgTagInfo ] = useState({ data: [] });
    const [ orgSizeInfo, setOrgSizeInfo ] = useState({ data: [] });
    const [ orgColorInfo, setOrgColorInfo ] = useState({ data: [] });
    const [ isLoading, setLoading ] = useState(false);
    const [ currentSearchStr, setCurrentSearchStr ] = useState('');
    const [ isLessThan, setIsLessThan ] = useState(false);
    const [ isgreaterThan, setIsgreaterThan ] = useState(false);
    const [ isInBetween, setIsInBetween ] = useState(false);
    const [ tagsSearchStr, setTagsSearchStr ] = useState('');
    const [ colorSearchStr, setColorSearchStr ] = useState('');
    const [ sizeSearchStr, setSizeSearchStr ] = useState('');
    const [ filterTagBy, setFilterTagBy ] = useState(1);
    const [ sortByType, setSortByType ] = useState(2);
    const [maxPrice, setMaxPrice] = useState(4000);
    const [minPrice, setMinPrice] = useState(0);
    const [intialMinMaxPriceRange, setIntialMinMaxPriceRange] = useState({ minPrice: 0, maxPrice: 10000});
    const [stockOptionValue, setStockOptionValue] = useState('');
    const [ showClearAll, setShowClearAll ] = useState(true);
    const [ filters, setFilters ] = useState({
        sort_by: 2,
        filter_tag: 0,
        min_price: 0,
        max_price: 0,
        tag_ids: [],
        color_ids: [],
        size_ids: [],
        is_stock: 2,
        is_greater_than: 0,
        greater_than_value: '',
        is_less_than: 0,
        less_than_value: ''
    });

    const [ sortByState, setSortByState ] = useState([
        {
            id: 2,
            name: "Design Name (A-Z)",
            isActive: true
        },
        {
            id: 3,
            name: "Design Name (Z-A)",
            isActive: false
        },
        {
            id: 4,
            name: "Price (Low- High)",
            isActive: false
        },
        {
            id: 5,
            name: "Price (High- Low)",
            isActive: false
        },
        {
            id: 6,
            name: "Stock (Low- High)",
            isActive: false
        },
        {
            id: 7,
            name: "Stock (High- Low)",
            isActive: false
        }
    ]);

    const [ stockByState, setStockByState ] = useState([
        {
            id: 2,
            name: "All",
            isActive: true
        },
        {
            id: 1,
            name: "In Stock",
            isActive: false
        },
        {
            id: 0,
            name: "Out of Stock",
            isActive: false
        }
    ]);

    const [filtersList, setFilterListNav] = useState( [
        { title: 'Sort By', activeCount: 0 },
        { title: 'Tags', activeCount: 0 },
        { title: 'Size', activeCount: 0 },
        { title: 'Colors', activeCount: 0 },
        { title: 'Stock', activeCount: 0 },
        { title: 'Price Range', activeCount: 0 }
    ]);
    
    useEffect(()=> {
        updateFilterActiveCounts();
    },[filters.tag_ids.length, filters.size_ids.length, filters.color_ids.length]);

    useEffect(()=> {
        const hasChanges = hasChangesInFilter();
        setShowClearAll(hasChanges);
        //console.log('hasChanges-->>>>>', hasChanges);
    })
    const { organization_id } = props.orgInfo;

    const handleFilterUpdate = (filterName, value) => {
        setFilters({ ...filters, [ filterName ]: value })
    }

    const handleTagSelection = (id, keyName) => {
        //console.log('handleSelection--->>> keyName', keyName, id);
        const isIncludes = filters[ keyName ].includes(id);
        //console.log('isIncludes-->>', isIncludes);
        if (!isIncludes) {
            filters[ keyName ].push(id);
            setFilters({ ...filters });
            // orgTagInfo.content.map(tag=> {
            //     tag.selected = true;
            // })
            // setOrgTagInfo({...orgTagInfo, content: orgTagInfo.content });
        } else {
            setFilters({ ...filters, [ keyName ]: filters[ keyName ].filter(t => t !== id) })
        }
    }

    const searchHandle = async (type, searchText) => {
        //console.log('[CategoriesList.js] searchHandle searchString ----->>>>>',type, searchText)
        // if (currentSearchStr) {
        //     setOrgTagInfo({ content: [] });
        //     setCurrentSearchStr(currentSearchStr);
        //     setLoading(true);
        // }

        switch (type) {
            case 1:
                getAllTags(searchText);
                break;
            case 2:
                getAllSizes(searchText);
                break;
            case 3:
                getAllColors(searchText);
                break;
            default:
                break;
        }
    };

    const sortByContent = useMemo(() => {
        return (<ListGroup defaultActiveKey="#link1" style={{margin:'0 0 0 15px',gap:'5px'}}>
            {sortByState.map((s, index) =>
                <ListGroup.Item className="filter-sortby-items" key={index} action onClick={() => setSortByType(s.id)} >
                    <input className="me-2" type="checkbox" checked={s.id === sortByType} onChange={() => { }} />{s.name}
                </ListGroup.Item>)
            }
        </ListGroup>)
    }, [sortByType])

    const handleOnScroll = (apiFun, dataObj) => {
        //console.log('handleOnScroll--->>>');
        if (dataObj && dataObj.page && dataObj.page.next && !isLoading) {
            apiFun();
        }
    }
    
    const tagsContent = () => {
    
        return (
            <div style={{margin:'0 0 0 15px'}} > 
                <SearchBarProductFilter
                    currentSearchStr={tagsSearchStr}
                    setCurrentSearchStr={setTagsSearchStr}
                    placeHolder={"Search tag"}
                    searchFunctionName={"searchHandle"}
                    searchHandle={(searchText) => searchHandle(1, searchText)}
                    inputClickHandler={()=> {}}
                    inputRef={undefined}
                    setData={setOrgTagInfo}
                />
                <label htmlFor="filter_tag" className="ps-1" style={{margin:'10px 0 15px 0'}}><b>Filter Tags using</b></label>
                <div></div>
                <select
                    className="form-select mb-1"
                    name="stock_value"
                    id="filter_tag" 
                    onChange={(event) => setFilterTagBy(Number(event.target.value))}
                    value={filterTagBy}
                >
                    <option value="1">All</option>
                    <option value="2">Any</option>
                    <option value="3">Not Included</option>
                </select>
                <ListGroup defaultActiveKey="#link2" className="product-fil-modal-scrolltags">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => handleOnScroll(getAllTags, orgTagInfo)}
                        hasMore={orgTagInfo.page && orgTagInfo.page.next ? true : false}
                        threshold={10}
                        useWindow={false}
                        isReverse={false}
                    // loader={<div className="loader" key={0}>Loading ...</div>}                        
                    >
                        {orgTagInfo.data.map((s, idx) =>
                            <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.pk_tagID, 'tag_ids')} >
                                <input type="checkbox" checked={filters.tag_ids.includes(s.pk_tagID)} onChange={() => { }} /> {s.tagName} 
                            </ListGroup.Item>)
                        }
                        
                        {orgTagInfo.data.length == 0 && !isLoading && 
                        <ListGroup defaultActiveKey="#link1">
                            <ListGroup.Item >No Tags</ListGroup.Item>
                        </ListGroup>}

                        {
                        isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >Loading ...</ListGroup.Item>
                            </ListGroup>
                        }
                        
                    </InfiniteScroll>
                </ListGroup>
            </div>
        )
    }

    const sizeContent = () => {

            return (
                <div style={{margin:'0 0 0 15px'}}>
                    <SearchBarProductFilter
                        currentSearchStr={sizeSearchStr}
                        setCurrentSearchStr={setSizeSearchStr}
                        placeHolder={"Search size"}
                        searchFunctionName={"searchHandle"}
                        searchHandle={(searchText) => searchHandle(2, searchText)}
                        inputClickHandler={()=> {}}
                        inputRef={undefined}
                        setData={setOrgSizeInfo}
                    />
                    <ListGroup defaultActiveKey="#link2" className="product-fil-modal-scroll" >
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => handleOnScroll(getAllSizes, orgSizeInfo)}
                            hasMore={orgSizeInfo.page && orgSizeInfo.page.next ? true : false}
                            threshold={10}
                            useWindow={false}
                            isReverse={false}
                        // loader={<div className="loader" key={0}>Loading ...</div>}                        
                        >

                            {orgSizeInfo.data.map((s, idx) =>
                                <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.size_id, 'size_ids')} >
                                    <input type="checkbox" checked={filters.size_ids.includes(s.size_id)} onChange={() => { }} /> {s.size_name}
                                </ListGroup.Item>)
                            }
                            {orgSizeInfo.data.length == 0 && !isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >No Sizes</ListGroup.Item>
                            </ListGroup>}

                            {
                        isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >Loading ...</ListGroup.Item>
                            </ListGroup>
                        }
                        </InfiniteScroll>
                    </ListGroup>
                </div>
            )

        // return (<ListGroup defaultActiveKey="#link1">
        //     {<ListGroup.Item >No sizes</ListGroup.Item>}
        // </ListGroup>);
    }

    const colorContent = () => {

        return (
            <div style={{margin:'0 0 0 15px'}}>
                <SearchBarProductFilter
                    currentSearchStr={colorSearchStr}
                    setCurrentSearchStr={setColorSearchStr}
                    placeHolder={"Search colors"}
                    searchFunctionName={"searchHandle"}
                    searchHandle={(searchText) => searchHandle(3, searchText)}
                    inputClickHandler={()=> {}}
                    inputRef={undefined}
                    setData={setOrgColorInfo}
                />
                <ListGroup defaultActiveKey="#link2" className="product-fil-modal-scroll">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => handleOnScroll(getAllColors, orgColorInfo)}
                        hasMore={orgColorInfo.page && orgColorInfo.page.next ? true : false}
                        threshold={10}
                        useWindow={false}
                        isReverse={false}
                    // loader={<div className="loader" key={0}>Loading ...</div>}                        
                    >
                        {orgColorInfo.data.map((s, idx) =>
                            <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.color_id, 'color_ids')} >
                                <input type="checkbox" checked={filters.color_ids.includes(s.color_id)} onChange={() => { }} /> {s.color_name}
                            </ListGroup.Item>)
                        }
                        {orgColorInfo.data.length == 0 && !isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >No tags</ListGroup.Item>
                            </ListGroup>}

                        {
                        isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >Loading ...</ListGroup.Item>
                            </ListGroup>
                        }
                    </InfiniteScroll>
                </ListGroup>
            </div>
        )
    }

    const stockFilterSelectionHandler = (key) => {
        setStockOptionValue(key);
        switch (key) {
            case 'is_greater_than':
                setFilters({
                    ...filters,
                    is_greater_than: 1,
                    greater_than_value: '',
                    is_less_than: 0,
                    less_than_value: ''
                });
                setIsgreaterThan(true);
                setIsLessThan(false);
                setIsInBetween(false);
                break;
            case 'is_less_than':
                setFilters({
                    ...filters,
                    is_greater_than: 0,
                    greater_than_value: '',
                    is_less_than: 1,
                    less_than_value: ''
                });
                setIsgreaterThan(false);
                setIsLessThan(true);
                setIsInBetween(false);
                break;
            case 'is_in_between':
                setFilters({
                    ...filters,
                    is_greater_than: 1,
                    greater_than_value: '',
                    is_less_than: 1,
                    less_than_value: ''
                });

                setIsgreaterThan(false);
                setIsLessThan(false);
                setIsInBetween(true);
                break;
            default:
                setFilters({
                    ...filters,
                    is_greater_than: 0,
                    greater_than_value: '',
                    is_less_than: 0,
                    less_than_value: ''
                });
                setIsgreaterThan(false);
                setIsLessThan(false);
                setIsInBetween(false);
                break;
        }

    }

    const stockContent = useMemo(() => {
        //console.log('stockContent-->>>>', filters.is_greater_than);
        return (
            <div style={{margin:'0 0 0 15px'}}>
                <ListGroup defaultActiveKey="#link1" >
                    {stockByState.map((s, index) =>
                        <ListGroup.Item className="filter-stock-items" key={index} action onClick={() => handleFilterUpdate('is_stock', s.id)} >
                            <input className="me-2" type="checkbox" checked={s.id === filters.is_stock} /> {s.name} 
                        </ListGroup.Item>)
                    }
                </ListGroup>

                <label htmlFor="stock_value" className="mt-3" style={{margin:'0px 0px 15px 0px'}}><b>Stock Value</b></label>
                <div></div>
                <select
                    className="form-select mb-3"
                    name="stock_value"
                    id="stock_value" 
                    value={stockOptionValue}
                    onChange={(event) => stockFilterSelectionHandler(event.target.value)}
                >
                    <option value="">Select Option</option>
                    <option value="is_greater_than">Is Greater Than</option>
                    <option value="is_less_than">Is Less Than</option>
                    <option value="is_in_between">In Between</option>
                </select>
                <Row>

                    {isgreaterThan || isInBetween ?
                        <Col>
                            <label htmlFor="is_greater_than" className="mb-1">Greater than</label>
                            <input type="text" id='is_greater_than' value={filters.greater_than_value} 
                                onChange={(e)=> setFilters({ ...filters ,greater_than_value: isNaN(e.target.value) || e.target.value == ''? '' :  Number(e.target.value)}) }
                                className="form-control"
                            /> 
                        </Col>
                        : null
                    }

                    {isLessThan || isInBetween ?
                        <Col>
                            <label htmlFor="is_less_than" className="mb-1">Less than</label>
                            <input type="text" id='is_less_than' value={filters.less_than_value} 
                                onChange={(e)=> setFilters( { ...filters, less_than_value: isNaN(e.target.value) || e.target.value == ''? '' : Number(e.target.value)})}
                                className="form-control"
                            /> 
                        </Col>: null
                    }
                </Row>
               
            </div>
        )
    }, [filters.is_stock, isgreaterThan, isInBetween, isLessThan, isInBetween, filters.less_than_value, filters.greater_than_value])

    const priceRange = useMemo(() => {
        return (
        <div style={{margin:'0 0 0 20px'}}>
            <h6>Design Price</h6>
            <div className="form-group mt-2 row"  >
                <div className="col">
                    <label htmlFor="min_price" className="ps-0 form-label">Min Price</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id='min_price' 
                        style={{ width: '100%' }} 
                        value={minPrice} 
                        onChange={(e)=> setMinPrice(e.target.value)}
                    />
                </div>
                <div className="col">
                    <label htmlFor="max_price" className="ps-0 form-label">Max Price</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id='max_price' 
                        style={{ width: '100%' }} 
                        value={maxPrice}
                        onChange={(e)=> setMaxPrice(e.target.value)}
                    />
                </div>
            </div>

            <div
                className="multi-range-slider-container"
            >
                <MultiRangeSlider
                   min={intialMinMaxPriceRange.minPrice}
                   max={intialMinMaxPriceRange.maxPrice}
                   minValue={minPrice}
                   maxValue={maxPrice}
                    canMinMaxValueSame={true}
                    onChange={(e) => {
                        //console.log('e.minValue-->>',e.minValue);
                        //console.log('e.maxValue-->>',e.maxValue);
                        setMaxPrice(e.maxValue);
                        setMinPrice(e.minValue);
                    }}
                    
                    label={false}
                    ruler={false}
                    style={{ border: "none", boxShadow: "none", padding: "30px 10px" }}
                    //   barLeftColor="red"
                    barInnerColor="#0191D0"
                    //   barRightColor="blue"
                    //   thumbLeftColor="lime"
                    //   thumbRightColor="lime"
                />
            </div>
        </div>)
    },[maxPrice, minPrice, intialMinMaxPriceRange])

    const onFilterView = () => {
        getAllTags();
        getAllColors();
        getAllSizes();
        getOrgMinMaxPrice();
        setShowFilterModal(true);
        if(props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1) {
            handleFilterUpdate('is_stock',1);
        }
    }

    const getAllTags = async (searchText) => {
        //console.log('[FilterMOdal.js] getAllTags1 >', orgTagInfo);
        //console.log('[FilterMOdal.js] getAllTags12 >', tagsSearchStr);
        //console.log('[FilterMOdal.js] getAllTags12 >', searchText);

        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (tagsSearchStr ? tagsSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const tagsRes = await getOrgTags(orgTagInfo, data);
        //console.log('[FilterMOdal.js] getAllTags2 >', tagsRes);
        if (tagsRes.status) {
            setOrgTagInfo({ ...tagsRes, data: [ ...orgTagInfo.data, ...tagsRes.data ] });
        }
        setLoading(false);
    }

    const getAllColors = async (searchText) => {
        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (colorSearchStr ? colorSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const colorsRes = await getOrgColors(orgColorInfo, data);
        //console.log('[ProductListing.js] getAllColors >', colorsRes);
        if (colorsRes.status) {
            setOrgColorInfo({ ...colorsRes, data: [ ...orgColorInfo.data, ...colorsRes.data ] });
        }
        setLoading(false);
    }

    const getAllSizes = async (searchText) => {
        //console.log('[ProductListing.js] getAllSizes 11>', orgSizeInfo);

        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (sizeSearchStr ? sizeSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const sizesRes = await getOrgSizes(orgSizeInfo, data);
        //console.log('[ProductListing.js] getAllSizes >res', sizesRes);

        if (sizesRes.status) {
            setOrgSizeInfo({ ...sizesRes, data: [ ...orgSizeInfo.data, ...sizesRes.data ] });
        }
        setLoading(false);
    }

    const getOrgMinMaxPrice = async () => {
        let data = {
            organization_id: organization_id
        };
        setLoading(true);
        const minMaxPriceRes = await getOrgDesignMinMaxPrice(data);

        if (minMaxPriceRes.status) {
            const maxPrice = isNaN(minMaxPriceRes.data.max_price) ? '' : Math.ceil(minMaxPriceRes.data.max_price);
            const minPrice = isNaN(minMaxPriceRes.data.min_price) ? '' : Math.ceil(minMaxPriceRes.data.min_price);
            setMaxPrice(maxPrice);
            setMinPrice(minPrice);
            setIntialMinMaxPriceRange({ minPrice, maxPrice});
        }

        setLoading(false);
    }
    
    const onModalCloseHandler = () => {
        setShowFilterModal(false);
        setOrgTagInfo({ data: [] });
        setOrgColorInfo({ data: [] });
        setOrgSizeInfo({ data: [] });
    }

    const onSubmitHandler = () => {
        //console.log('onSubmitHandler--->>', filters);
        props.filterHandle({
            ...filters,
            tag_ids: filters.tag_ids?.toString(),
            color_ids: filters.color_ids?.toString(),
            size_ids: filters.size_ids?.toString(),
            min_price: minPrice,
            max_price: maxPrice,
            filter_tag: filterTagBy,
            sort_by: sortByType,
            is_greater_than: isNaN(filters.greater_than_value) || filters.greater_than_value === '' ? 0 : 1,
            is_less_than:  isNaN(filters.less_than_value) || filters.less_than_value === '' ? 0 : 1,
        });
        setTagsSearchStr('');
        setColorSearchStr('');
        setSizeSearchStr('');
        setShowFilterModal(false);

        setOrgTagInfo({ data: [] });
        setOrgColorInfo({ data: [] });
        setOrgSizeInfo({ data: [] });
    }

    const clearAllFilters = () => {
        //console.log('clearFilter--->>', filters);
        const resetFilters = {
            sort_by: 2,
            filter_tag: 0,
            min_price: 0,
            max_price: 0,
            tag_ids: [],
            color_ids: [],
            size_ids: [],
            is_stock: props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1 ? 1 : 2,
            is_greater_than: 0,
            greater_than_value: '',
            is_less_than: 0,
            less_than_value: ''
        };

        setFilters({ ...resetFilters });
        props.filterHandle({
            ...resetFilters,
            tag_ids: '',
            color_ids: '',
            size_ids: ''
        });

        setStockOptionValue('');
        setIsInBetween(false);
        setIsLessThan(false);
        setIsgreaterThan(false);
        
        setFilterListNav( [
            { title: 'Sort By', activeCount: 0 },
            { title: 'Tags', activeCount: 0 },
            { title: 'Size', activeCount: 0 },
            { title: 'Colors', activeCount: 0 },
            { title: 'Stock', activeCount: 0 },
            { title: 'Price Range', activeCount: 0 }
        ]);

        setSortByType(2);
        onModalCloseHandler();
    }

    const updateFilterActiveCounts = ()=> {
        const filtersT = cloneDeep(filtersList);
        filtersT[1].activeCount = filters.tag_ids && filters.tag_ids.length ? filters.tag_ids.length : 0;
        filtersT[2].activeCount = filters.size_ids && filters.size_ids.length ? filters.size_ids.length : 0;
        filtersT[3].activeCount = filters.color_ids && filters.color_ids.length ? filters.color_ids.length : 0;
        setFilterListNav([...filtersT])
    }

    const hasChangesInFilter = () => {
        // Check if both objects have the same keys
        const obj1 = filters;
        const obj2 = {
            sort_by: 2,
            filter_tag: 0,
            min_price: 0,
            max_price: 0,
            tag_ids: [],
            color_ids: [],
            size_ids: [],
            is_stock: props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1 ? 1 : 2,
            is_greater_than: 0,
            greater_than_value: '',
            is_less_than: 0,
            less_than_value: ''
        };

        const keys1 = Object.keys(filters);
        
        if(sortByType != 2) return true;

        for (const key of keys1) {
            // If the key is not present in the second object
            if (!obj2.hasOwnProperty(key)) {
                return true;
            }

            // Special handling for arrays

            if (Array.isArray(obj1[key])) {
                if (obj1[key].length) {
                    return true;
                }
            } else {
                if(obj1[key] != obj2[key]) {
                    return true;
                }
            }
            
        }
    
        return false;
    }

    //console.log('props.orgInfo--->>>',filtersList);
    return (
        <div className="col-md-5 col-sm-5" style={{padding:'20px'}}>
            <div className="filter-bx">
                {/* <Dropdown onSelect={handleOnSelect}>
                    <Dropdown.Toggle className="btn dropdown-toggle" id="dropdown-basic">
                        <i className="fas fa-filter"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="right-0 mt-5">
                        {props.dropDownData.map((dd, ind) => (
                            <Dropdown.Item className={(dd.isActive ? 'active' : '') + (!ind ? ' top-border ' : '')} key={dd.id} eventKey={dd.id}>{dd.name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown> */}
                <Button 
                    className="btn dropdown-toggle mobile-filter-btn" 
                    onClick={() => onFilterView()}
                >
                        <i class="fas fa-filter" style={{margin:'4px 0 0 -2px'}}></i>
                        {showClearAll ? <span className="modal-filter-indicator">.</span> : null}
                </Button>
                <Modal
                    bssize="large"
                    aria-labelledby="contained-modal-title-lg"
                    show={showFilterModal}
                    onHide={onModalCloseHandler}
                   className="border-rounded-0"
                   style={{paddingRight:'0px'}}
                >
                    <Modal.Header className="row" style={{margin:'1px'}}>
                        <Modal.Title id="contained-modal-title-lg" className="col-11"> <b>Product Filters</b></Modal.Title>
                        <Button className="product-filter-modal-btn-clear col-1"onClick={() => setShowFilterModal(false)}><b><i style={{margin:'-10px'}} class="fa fa-times"></i></b></Button>
                    </Modal.Header>

                    <Modal.Body style={{borderRadius: '0px !important'}} className="test-nk">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0" className="test-nk">
                            <Row style={{height: '400px'}}>
                                <Col xs={4} className="px-0 border-end" >
                                    <Nav variant="pills" className="flex-column">
                                        {filtersList.map((f, index) => 
                                            <Nav.Item key={index}>
                                                 <Nav.Link eventKey={index}>{f.title} {f.activeCount > 0 ?<span className="filter-checked-item-count">{f.activeCount }</span>: ''}</Nav.Link> 
                                            </Nav.Item>)}
                                    </Nav>
                                </Col>
                                <Col xs={8} className="ps-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="0">
                                            {sortByContent}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="1">
                                            {tagsContent()}
                                        </Tab.Pane>
                                            
                                        <Tab.Pane eventKey="2">
                                            {sizeContent()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="3">
                                            {colorContent()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="4">
                                            {stockContent}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="5">
                                            {priceRange}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer className="row" style={{margin:'1px'}}>
                        {/* <Button className="product-filter-modal-btn-clear col" style={{backgroundColor:'red'}} onClick={clearAllFilters}><b>Clear</b></Button> */}
                        <Button className="product-filter-modal-btn-close col" onClick={clearAllFilters}><b>Clear All</b></Button>
                        <Button className="product-filter-modal-btn col" onClick={onSubmitHandler}><b>Apply</b></Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    );
};


ProductFilterModal.propTypes = {
    dropDownData: PropTypes.array.isRequired,
};

ProductFilterModal.defaultProps = {
    dropDownData: []
};
export default ProductFilterModal;