import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import OrgInfoContext from "../Contexts/OrgInfo.context";
import { getProductDetails, saveCart } from "../config/services";
import { placeHolderImages } from "../config/constant";
import { toast } from 'react-toastify';


const ProductQtyModal = () => {
    const { setProductCardActiveInfo, productQtyModelDesId, setProductQtyModelDesId, setFullBgBlur, orgInfo, customerInfo } = useContext(OrgInfoContext);
    const [loader, setLoader] = useState(false);
    const [isShowCopyModal, setIsShowCopyModal] = useState(null);
    const [isCopyModelFromColorWise, setIsCopyModelFromColorWise] = useState(false);
    const [designInfo, setDesignInfo] = useState(null);
    useEffect(async () => {
        if (productQtyModelDesId) {
            setLoader(true);
            setFullBgBlur(true);
            try {
                const productInfo = await getProductDetails(orgInfo.store_url, productQtyModelDesId.designId, customerInfo?.pk_customerOrgID || "", productQtyModelDesId.categoryId, customerInfo.customerCategoryId);
                // console.log('[ProductQtyModal.js] useEffect productInfo>', productInfo);
                if (productInfo.status) {
                    setDesignInfo(productInfo.data);
                }
            } catch (error) {
                console.error('[ProductQtyModal.js] Use Effect Exception >', error);
            } finally {
                setLoader(false);
                setFullBgBlur(false);
            }
        }

        return () => {
            setLoader(false);
            setProductQtyModelDesId(null);
            setDesignInfo(null);
            setFullBgBlur(false);
        };
    }, [productQtyModelDesId]);
    const onHideHandler = () => {
        setProductQtyModelDesId(null);
        setDesignInfo(null);
        setFullBgBlur(false);
    };
    const onChangeHandler = (event, colorIndex, sizeIndex) => {
        if (designInfo) {
            const tempDesInfo = JSON.parse(JSON.stringify(designInfo));
            tempDesInfo.color_size_data[colorIndex].size_info[sizeIndex].quantity = parseFloat(event.target.value) * 1;
            setDesignInfo(tempDesInfo);
        }
    };
    const checkIsQtyThere = () => {
        let isQty = 0;
        for (const col of designInfo?.color_size_data || []) {
            if (isQty) {
                break;
            }
            for (const sz of col?.size_info || []) {
                if (sz?.quantity && parseFloat(sz.quantity) > 0) {
                    isQty = 1;
                    return 1;
                    break;
                }
            }
        }
        return isQty;
    };
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        // console.log('[ProductQtyModal.js] onSubmitHandler designInfo >', designInfo);

        try {
            // Check if qty exists or not
            if (!checkIsQtyThere()) {
                toast.warn('Please Enter Qty To Proceed Further');
                return false;
            }

            const saveToCart = await saveCart(orgInfo.store_url, 0, customerInfo.pk_customerOrgID, designInfo.design_id, designInfo.color_size_data, designInfo.list_type);
            if (saveToCart?.status) {
                if (saveToCart?.data?.message) {
                    toast.success(saveToCart?.data?.message || "Cart Updated Successfully...");
                    setProductCardActiveInfo({
                        designId: designInfo?.design_id,
                        isActive: 1
                    });
                    onHideHandler();
                }
            } else {
                throw saveToCart;
            }
        } catch (error) {
            console.error('[ProductQtyModal.js] > onSubmitHandler error', error);
        }
    };
    const copyQty = (event, colorInd, isFromColorWise = false) => {
        // console.log('[ProductQtyModal.js] copyQty colorId >' + colorInd);
        setIsShowCopyModal(colorInd);
        setIsCopyModelFromColorWise(isFromColorWise);
    };
    const copySubmitHandler = () => {
        // console.log('[ProductQtyModal.js] copySubmitHandler isShowCopyModal >' + isShowCopyModal);
        if (isShowCopyModal !== null) {
            const tempDesInfo = JSON.parse(JSON.stringify(designInfo));
            // Check if existing have some qty 
            if (!isCopyModelFromColorWise) {
                const existingQty = tempDesInfo.color_size_data[isShowCopyModal].size_info[0]?.quantity;
                if (!existingQty) {
                    toast.warn('You have not entered any qty.');
                } else {
                    tempDesInfo.color_size_data[isShowCopyModal].size_info = tempDesInfo.color_size_data[isShowCopyModal].size_info.map(si => {
                        si.quantity = parseFloat(existingQty) * 1;
                        return si;
                    });
                    setDesignInfo(tempDesInfo);
                }
            } else {
                const existingQty = tempDesInfo.color_size_data[0].size_info[0]?.quantity;
                if (!existingQty) {
                    toast.warn('You have not entered any qty.');
                } else {
                    tempDesInfo.color_size_data = tempDesInfo.color_size_data.map(csi => {
                        csi.size_info[0].quantity = parseFloat(existingQty) * 1;
                        return csi;
                    });
                    setDesignInfo(tempDesInfo);
                }
            }
        }
        setIsShowCopyModal(null);
    };
    const formData = (
        <form onSubmit={onSubmitHandler} >
            <div className="cart-head">
                <div className="cart-proimg">
                    <img src={designInfo?.design_images && designInfo?.design_images?.length ? designInfo.design_images[0] : placeHolderImages.DESIGN} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt="" />
                </div>
                <div className="cart-dt">
                    <h4>{productQtyModelDesId?.designName || designInfo?.design_number || ""}</h4>
                    <p>{productQtyModelDesId?.categoryName || designInfo?.category_name || ""}</p>
                </div>
            </div>
            {designInfo && designInfo?.is_stock_available ? (<>
                <div className="cart-data table-responsive">
                    <div className="cart-hed">
                        <ul className="pl-0">
                            <li>{designInfo?.list_type == 1 ? "" : designInfo?.list_type == 2 ? "COLOR" : designInfo?.list_type == 3 ? "SIZE" : "COLOR / SIZE"}</li>
                            <li className="quty">QTY.</li>
                            <li className="prc">PRICE</li>
                            <li className="epclone"></li>
                        </ul>
                    </div>
                    <div className="inner-cart-lst mCustomScrollbar">
                        {/* No Color No Size */}
                        {designInfo?.list_type == 1 ?
                            <div className="cart-editbx">
                                <ul className="pl-0">
                                    <li></li>
                                    <li className="quty">
                                        <div className="inbx">
                                            {
                                                designInfo?.color_size_data[0].size_info[0]?.is_stock_available ? (
                                                    <input type="number" defaultValue={designInfo?.color_size_data[0].size_info[0]?.quantity || ""} onChange={(event) => onChangeHandler(event, 0, 0)} min={0} placeholder="Qty." className="form-control" />
                                                ) : (
                                                    <div className="text-danger">Out Of Stock</div>
                                                )
                                            }
                                        </div>
                                    </li>
                                    <li className="prc">
                                        {orgInfo.store_json.show_rate ?
                                            <div className="pricbx">
                                                <i className={designInfo?.sale_price ? "fal fa-rupee-sign" : ""}></i> {designInfo?.sale_price || "N/A"}
                                            </div> : "N/A"
                                        }
                                    </li>
                                    <li className="epclone"></li>
                                </ul>
                            </div>
                            : null}
                        {/* With Color / Size / Both */}
                        {
                            designInfo?.list_type > 1 ?
                                designInfo?.color_size_data && designInfo.color_size_data.map((cs, csId) => {
                                    // Check if list type is not size wise only
                                    let colorWise = {};
                                    // if (designInfo?.list_type != 3) {
                                    const colWiseHtml = (
                                        <>
                                            {designInfo?.list_type != 3 ?
                                                <div className={"cart-editbx " + (csId ? "boron" : "")}>
                                                    <ul className="pl-0">
                                                        <li>
                                                            <h3>{cs.color_name}</h3>
                                                        </li>
                                                        <li className="quty">
                                                            {designInfo?.list_type == 2 ?
                                                                (<div className="inbx">
                                                                    {
                                                                        designInfo?.color_size_data[csId].size_info[0]?.is_stock_available ?
                                                                            (
                                                                                <input type="number" defaultValue={designInfo?.color_size_data[csId].size_info[0]?.quantity || ""} onChange={(event) => onChangeHandler(event, csId, 0)} min={0} placeholder="Qty." className="form-control" />
                                                                            )
                                                                            :
                                                                            (<div className="text-danger">Out Of Stock</div>)

                                                                    }
                                                                </div>)
                                                                : null}
                                                        </li>
                                                        <li className="prc">
                                                            {designInfo?.list_type == 2 ?
                                                                orgInfo.store_json.show_rate ?
                                                                    <div className="pricbx">
                                                                        <i className={designInfo?.sale_price ? "fal fa-rupee-sign" : ""}></i> {designInfo?.sale_price || "N/A"}
                                                                    </div> : "N/A"
                                                                :
                                                                null
                                                            }
                                                        </li>
                                                        <li className="epclone">
                                                            {!csId && designInfo?.list_type == 2 ? <a onClick={(event) => {
                                                                if (designInfo?.color_size_data[csId].size_info[0]?.quantity && designInfo?.color_size_data[csId].size_info[0]?.is_stock_available) {
                                                                    copyQty(event, csId, true);
                                                                }
                                                            }} className="copyic cp"><i className="far fa-clone"></i></a> : null}

                                                        </li>
                                                    </ul>
                                                </div>
                                                :
                                                null
                                            }
                                            <div>
                                                {
                                                    designInfo?.list_type != 2 && cs?.size_info && cs.size_info.map((si, siInd) => {
                                                        return (
                                                            <div className={"cart-editbx " + (designInfo?.list_type == 3 && siInd ? "boron" : "")}>
                                                                <ul className="pl-0">
                                                                    <li>{si.size_name}</li>
                                                                    <li className="quty">
                                                                        <div className="inbx">
                                                                            {
                                                                                designInfo?.color_size_data[csId].size_info[siInd]?.is_stock_available ?
                                                                                    (
                                                                                        <input type="number" value={designInfo?.color_size_data[csId].size_info[siInd]?.quantity || ""} defaultValue={designInfo?.color_size_data[csId].size_info[siInd]?.quantity || ""} onChange={(event) => onChangeHandler(event, csId, siInd)} placeholder="Qty." className="form-control" />
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <div className="text-danger">Out Of Stock</div>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                    <li className="prc">
                                                                        {orgInfo.store_json.show_rate ?
                                                                            <div className="pricbx">
                                                                                <i className={(si?.size_rate || si.size_rate || designInfo?.sale_price) ? "fal fa-rupee-sign" : ""}></i> {si?.size_rate || si.size_rate || designInfo?.sale_price || "N/A"}
                                                                            </div> : "N/A"
                                                                        }
                                                                    </li>
                                                                    <li className="epclone">
                                                                        {!siInd ? <a onClick={(event) => {
                                                                            if (designInfo?.color_size_data[csId].size_info[siInd]?.quantity && designInfo?.color_size_data[csId].size_info[siInd]?.is_stock_available) {
                                                                                copyQty(event, csId);
                                                                            }
                                                                        }} className="copyic cp"><i className="far fa-clone"></i></a> : null}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                        </>
                                    );

                                    colorWise = colWiseHtml;
                                    // }

                                    // console.log('colorWise >>> ', colorWise);
                                    return colorWise;
                                })
                                :
                                null
                        }
                    </div>
                </div>

                <div className="cart-actionbar">
                    <a className="btn-main calbtn cp" onClick={onHideHandler}>CANCEL</a>
                    <button type="submit" className="btn-main">SAVE</button>
                </div>
            </>
            )
                :
                <>
                    <div className="text-center text-danger">
                        Out Of Stock
                    </div>
                    <div className="cart-actionbar">
                        <a className="btn-main calbtn cp" onClick={onHideHandler}>Done</a>
                    </div>
                </>
            }
        </form>
    );
    return (
        <>
            {
                productQtyModelDesId?.isFromMobile ?
                    <>
                        <div /* onClick={onHideHandler} */ className={((productQtyModelDesId && !loader && designInfo) ? "mobile-menu-open" : "") + " back-blur-fx add-clbx"}></div>
                        <div className={((productQtyModelDesId && !loader && designInfo) ? "mobile-menu-open" : "") + " modal-content add-clbx mobilecart-aside"} style={{ "background-color": "white" }}>
                            {formData}
                        </div>
                    </> :
                    <Modal className="background-blurbx" /* onHide={onHideHandler} */ show={(productQtyModelDesId && !loader && designInfo) ? true : false}>
                        {formData}
                    </Modal>

            }
            {loader ?
                <div  >
                    <h2>Loading .... </h2>
                </div>
                :
                <>
                    <Modal className="background-blurbx modal marginAuto" onHide={() => { }} show={isShowCopyModal !== null ? true : false}>
                        <div className="copy-popup-block">
                            <h2>Copy Quantity</h2>
                            <p>Are you sure you want to copy the item quantity to all {!isCopyModelFromColorWise ? 'sizes' : 'colors'} ?</p>

                            <div className="cart-actionbar">
                                <button onClick={() => setIsShowCopyModal(null)} className="btn-main calbtn" data-dismiss="modal">NO</button>
                                <button onClick={copySubmitHandler} className="btn-main">YES</button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </>

    );
};

export default ProductQtyModal;